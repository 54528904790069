import { createBrowserHistory as createHistory } from "history";
import { syncHistoryWithStore } from "mobx-react-router";
import VeracityApi from "../httpClients/veracityApi";
import ClippyApi from "../httpClients/clippyApi";
import JaredApi from "../httpClients/jaredApi";
import MlbTrackingAccuracyReportStore from "./mlbTrackingAccuracyReportStore";
import MilbTrackingAccuracyReportStore from "./milbTrackingAccuracyReportStore";
import SetiRouterStore from "./setiRouterStore";
import AuthStore from "./authStore";
import MILBGameActionStore from "./milbGameActionStore";
import NonMLBTrackingAccuracyReportStore from "./nonMLBTrackingAccuracyReportStore";
import AAATrackingAccuracyReportStore from "./aaaTrackingAccuracyReportStore";
import AATrackingAccuracyReportStore from "./aaTrackingAccuracyReportStore";
import HighATrackingAccuracyReportStore from "./highaTrackingAccuracyReportStore";
import ATrackingAccuracyReportStore from "./aTrackingAccuracyReportStore";
import RookieTrackingAccuracyReportStore from "./rookieTrackingAccuracyReportStore";
import AmateurTrackingAccuracyReportStore from "./amateurTrackingAccuracyReportStore";
import WinterTrackingAccuracyReportStore from "./winterTrackingAccuracyReportStore";
import InternationalIndependentTrackingAccuracyReportStore from "./internationalIndependentTrackingAccuracyReportStore";
import { Auth } from "@okta/okta-react";
import { RouteConstants } from "../constants/RouteConstants";
import VeracityIncidentStore from "./veracityStore";
import LatestIncidentsStore from "./latestIncidentsStore";
import TableControlsStore from "./tableControlsStore";
import GameActionStore from "./GameActionStore";

const onAuthRequired = ({ history }) => {
  history.push(RouteConstants.LOGIN);
};

class RootStore {
  constructor() {
    this.routerStore = new SetiRouterStore();
    this.history = syncHistoryWithStore(createHistory(), this.routerStore);
    const auth = new Auth({
      history: this.history,
      issuer: process.env.REACT_APP_OKTA_ISSUER,
      clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
      redirectUri: window.location.origin + "/implicit/callback",
      onAuthRequired: onAuthRequired,
    });
    this.authStore = new AuthStore(this.routerStore, auth);
    this.authStore.getUser();
    this.veracityApi = new VeracityApi();
    this.clippyApi = new ClippyApi();
    this.jaredApi = new JaredApi();

    this.veracityStore = new VeracityIncidentStore(
      this.veracityApi,
      this.routerStore,
      this.authStore,
    );

    this.milbTrackingAccuracyReportSource = new MilbTrackingAccuracyReportStore(
      this.veracityApi,
      this.routerStore,
      this.veracityStore,
    );

    this.milbGameActionStore = new MILBGameActionStore(
      this.jaredApi,
      this.milbTrackingAccuracyReportSource,
    );

    // MLB works fine because I didn't pass in Minors Sports Ids
    this.MLBGameActionStore = new GameActionStore(
      this.clippyApi,
      this.jaredApi,
      this.veracityApi,
      new MlbTrackingAccuracyReportStore(
        this.veracityApi,
        this.routerStore,
        this.veracityStore,
      ),
    );

    this.nonMLBGameActionStore = new GameActionStore(
      this.clippyApi,
      this.jaredApi,
      this.veracityApi,
      new NonMLBTrackingAccuracyReportStore(
        this.veracityApi,
        this.routerStore,
        this.veracityStore,
      ),
    );

    this.AAAGameActionStore = new GameActionStore(
      this.clippyApi,
      this.jaredApi,
      this.veracityApi,
      new AAATrackingAccuracyReportStore(
        this.veracityApi,
        this.routerStore,
        this.veracityStore,
      ),
    );

    this.AAGameActionStore = new GameActionStore(
      this.clippyApi,
      this.jaredApi,
      this.veracityApi,
      new AATrackingAccuracyReportStore(
        this.veracityApi,
        this.routerStore,
        this.veracityStore,
      ),
    );

    this.HighAGameActionStore = new GameActionStore(
      this.clippyApi,
      this.jaredApi,
      this.veracityApi,
      new HighATrackingAccuracyReportStore(
        this.veracityApi,
        this.routerStore,
        this.veracityStore,
      ),
    );

    this.AGameActionStore = new GameActionStore(
      this.clippyApi,
      this.jaredApi,
      this.veracityApi,
      new ATrackingAccuracyReportStore(
        this.veracityApi,
        this.routerStore,
        this.veracityStore,
      ),
    );

    this.RookieGameActionStore = new GameActionStore(
      this.clippyApi,
      this.jaredApi,
      this.veracityApi,
      new RookieTrackingAccuracyReportStore(
        this.veracityApi,
        this.routerStore,
        this.veracityStore,
      ),
    );

    this.AmateurGameActionStore = new GameActionStore(
      this.clippyApi,
      this.jaredApi,
      this.veracityApi,
      new AmateurTrackingAccuracyReportStore(
        this.veracityApi,
        this.routerStore,
        this.veracityStore,
      ),
    );

    this.WinterGameActionStore = new GameActionStore(
      this.clippyApi,
      this.jaredApi,
      this.veracityApi,
      new WinterTrackingAccuracyReportStore(
        this.veracityApi,
        this.routerStore,
        this.veracityStore,
      ),
    );

    this.InternationalIndependentGameActionStore = new GameActionStore(
      this.clippyApi,
      this.jaredApi,
      this.veracityApi,
      new InternationalIndependentTrackingAccuracyReportStore(
        this.veracityApi,
        this.routerStore,
        this.veracityStore,
      ),
    );

    this.latestIncidentsStore = new LatestIncidentsStore(
      this.veracityApi,
      this.routerStore,
    );
    this.tableControlsStore = new TableControlsStore();
  }
}

export default RootStore;
