import IncidentTypes from "../constants/IncidentTypes";
import { RouteConstants } from "../constants/RouteConstants";
import TrackingAccuracyReportStore from "./trackingAccuracyReportStore";

class AmateurTrackingAccuracyReportStore extends TrackingAccuracyReportStore {
  fetchTrackingAccuracyReport(date) {
    return this.veracityApi.fetchAmateurTrackingAccuracyReport(date);
  }

  cancelTrackingAccuracyReport() {
    return this.veracityApi.cancelAmateurTrackingAccuracyReport();
  }

  isTrackingTabActive() {
    return this.routerStore.isAmateurTrackingAccuracyReportTab;
  }

  updateNewIncidentMetadata(game) {
    return this.veracityStore.updateNewIncidentMetadata(
      IncidentTypes.Amateur,
      game,
      this.get("date"),
      RouteConstants.AMATEUR_TRACKING,
    );
  }
}

export default AmateurTrackingAccuracyReportStore;
