import React from "react";
import _ from "lodash";
import Tooltip from "rc-tooltip";
import { Table, TableHeaderRow, Tr, Th, Td } from "../SetiTable.styled";
import MatchupLink from "../elements/MatchupLink";
import moment from "moment";
import Check from "../icons/Check";
import {
  PointerText,
  TooltipWrapper,
  RangeTd,
  WARN_COLOR,
  CRITICAL_COLOR,
  PPD_COLOR,
  GameStatusIcon
} from "./ReportElements";
import GameActionsDropDown from "../GameActionsDropDown";

const headerColumns = [
  {
    label: "Game",
    textAlignment: "left"
  },
  {
    label: "GamePK",
    textAlignment: "right"
  },
  {
    label: "Game Time",
    textAlignment: "right"
  },
  { label: "Actions" },
  {
    label: "Pitches Tracked",
    textAlignment: "right"
  },
  {
    label: "Pitches w/ Spin Rate",
    textAlignment: "right"
  },
  {
    label: "BIP Tracked",
    textAlignment: "right"
  }
];

const getDataColumns = record => {
  const { status = {} } = record.game;
  if (status.codedGameState === "D") {
    return (
      // PPD the size of the data columns
      <Td bgcolor={PPD_COLOR} colSpan={_.keys(record).length - 2}>
        PPD
      </Td>
    );
  }

  return (
    <React.Fragment>
      {getPitchesTracked(record)}
      {getPitchesWithSpinRate(record)}
      {getBIPTracked(record)}
    </React.Fragment>
  );
};

const getPitchesTracked = record => {
  const { playIds, pitchesTracked } = record;
  return (
    <RangeTd
      count={pitchesTracked}
      totalPitches={playIds.length}
      stops={[[0, CRITICAL_COLOR], [0.99, WARN_COLOR], [0.995]]}
    />
  );
};

const getPitchesWithSpinRate = record => {
  const { playIds, pitchesWithSpinRate } = record;
  return (
    <RangeTd
      count={pitchesWithSpinRate}
      totalPitches={playIds.length}
      stops={[[0, CRITICAL_COLOR], [0.99, WARN_COLOR], [0.995]]}
    />
  );
};

const getBIPTracked = record => {
  const { ballsInPlay, ballsInPlayTracked } = record;
  return (
    <RangeTd
      count={ballsInPlay}
      totalPitches={ballsInPlayTracked}
      stops={[[0, CRITICAL_COLOR], [0.99, WARN_COLOR], [0.995]]}
    />
  );
};

const AFLTrackingAccuracyReportTable = ({
  data = [],
  transitionToCreateIncident,
  canCreateIncident
}) => {
  const gameActionOptions = [
    { label: "Create Incident", value: "create_incident" }
  ];

  const headerRow = (
    <TableHeaderRow>
      {headerColumns
        .filter(header => canCreateIncident || header.label !== "Actions")
        .map((column, idx) => {
          const { label, description, textAlignment, colspan } = column;
          const hasDescription = !_.isEmpty(description);

          const thContent = !hasDescription ? (
            label
          ) : (
            <Tooltip
              placement="top"
              overlay={<TooltipWrapper>{description}</TooltipWrapper>}
            >
              <PointerText>{label}</PointerText>
            </Tooltip>
          );

          return (
            <Th
              key={idx}
              textAlignment={textAlignment}
              hoverable={hasDescription}
              colSpan={colspan}
            >
              {thContent}
            </Th>
          );
        })}
    </TableHeaderRow>
  );

  const bodyRows =
    data && data.records
      ? data.records.map(record => {
          const { game } = record;

          return (
            <Tr key={game.gamePk}>
              <Td textAlignment="left">
                <div className="flex-between">
                  <MatchupLink game={game} useFullNames={true} />
                  <GameStatusIcon>
                    <Check shape="circle" />
                  </GameStatusIcon>
                </div>
              </Td>
              <Td textAlignment="right">{game.gamePk}</Td>
              <Td textAlignment="right" noWrap>
                {moment(game.gameDate)
                  .local()
                  .format("LT")}
              </Td>
              {canCreateIncident ? (
                <Td noPadding>
                  <GameActionsDropDown
                    game={game}
                    gameActionOptions={gameActionOptions}
                    transitionToCreateIncident={transitionToCreateIncident}
                  />
                </Td>
              ) : null}
              {getDataColumns(record)}
            </Tr>
          );
        })
      : [];

  const footerRows = (
    <React.Fragment>
      <Tr>
        <td>&#8203;</td>
      </Tr>
      <Tr>
        <Td noborder />
        <Td noborder />
        <Td noborder />
        {canCreateIncident && <Td noborder />}
        <Td bgcolor={WARN_COLOR} colSpan={3}>
          99% to 99.5%
        </Td>
      </Tr>
      <Tr>
        <Td noborder />
        <Td noborder />
        <Td noborder />
        {canCreateIncident && <Td noborder />}
        <Td bgcolor={CRITICAL_COLOR} colSpan={3}>
          {"< 99%"}
        </Td>
      </Tr>
    </React.Fragment>
  );

  return (
    <Table width="100%">
      <colgroup>
        <col width="29%" />
        <col width="6%" />
        <col width="6%" />
        {canCreateIncident && <col width="14%" />}
        <col width="15%" />
        <col width="15%" />
        <col width="15%" />
        <col width="14%" />
      </colgroup>
      <thead>{headerRow}</thead>
      <tbody>{bodyRows}</tbody>
      <tfoot>{footerRows}</tfoot>
    </Table>
  );
};

export default AFLTrackingAccuracyReportTable;
