import React from "react";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";

export default WrappedComponent => {
  class AdminOrSuperProtected extends React.Component {
    render() {
      const {
        rootStore: { authStore }
      } = this.props;
      if (authStore.isAdminOrSuperUser) {
        return <WrappedComponent {...this.props} />;
      } else if (authStore.isGettingUser) {
        return null;
      } else {
        return <Redirect to={RouteConstants.BASE} />;
      }
    }
  }

  return inject("rootStore")(observer(AdminOrSuperProtected));
};
