import React from "react";
import _ from "lodash";
import Tooltip from "rc-tooltip";
import TrackingAccuracyDescriptions from "../../constants/trackingAccuracyDescriptions";
import {
  Table,
  TableHeaderRow,
  Tr,
  Th,
  Td,
  TableAggregateHealthRow,
} from "../SetiTable.styled";
import MatchupLink from "../elements/MatchupLink";
import moment from "moment";
import Check from "../icons/Check";
import Resend from "../icons/Resend";

import {
  PointerText,
  TooltipWrapper,
  RangeTd,
  WARN_COLOR,
  CRITICAL_COLOR,
  PPD_COLOR,
  BG_COLOR,
  GameStatusIcon,
  AggregateTd,
} from "./ReportElements";
import GameNote from "../notes/GameNote";
import CheckWithExclamation from "../icons/CheckWithExclamation";
import Exclamation from "../icons/Exclamation";

function infoHeaderCells(canCreateIncident) {
  return [
    {
      label: "Game",
      textAlignment: "left",
    },
    {
      label: "Actions",
      textAlignment: "left",
    },
    {
      label: "GamePK",
      textAlignment: "right",
    },
    {
      label: "Level",
      textAlignment: "right",
    },
    {
      label: "League",
      textAlignment: "right",
    },
    {
      label: "Game Time",
      textAlignment: "right",
    },
    {
      label: "Pitches With Broadcast Video",
      description: TrackingAccuracyDescriptions.PITCHES_WITH_BROADCAST_VIDEO,
      textAlignment: "center",
    },
  ].filter((header) => canCreateIncident || header.label !== "Actions");
}

const getDataColumns = (game, tracking) => {
  const { status = {} } = game;
  if (status.codedGameState === "D") {
    return (
      // PPD the size of the data columns (except for totalPlays and totalHits)
      <Td bgcolor={PPD_COLOR} colSpan={_.keys(tracking).length - 2}>
        PPD
      </Td>
    );
  }

  const { guidCount, stringerCount, isTracked } = tracking;

  if (!isTracked) {
    return (
      <Td bgcolor={PPD_COLOR} colSpan={_.keys(tracking).length - 2}>
        N/A
      </Td>
    );
  }
  if (status.codedGameState === "C") {
    return (
      // PPD the size of the data columns (except for totalPlays and totalHits)
      <Td bgcolor={PPD_COLOR} colSpan={_.keys(tracking).length - 2}>
        Cancelled
      </Td>
    );
  }
  return (
    <RangeTd
      count={guidCount}
      totalPitches={stringerCount}
      stops={[[0, CRITICAL_COLOR], [0.99, WARN_COLOR], [0.995]]}
    />
  );
};

const getAggregateDataColumns = (trackingPerGame) => {
  let trackingAggr = _.transform(
    trackingPerGame[0],
    (result, value, key) => (result[key] = 0),
    {},
  );
  _.chain(trackingPerGame)
    .filter((tracking) => tracking.isTracked)
    .reduce((trackingAggr, x) => {
      return _.forEach(
        trackingAggr,
        (value, key) => (trackingAggr[key] += x[key]),
      );
    }, trackingAggr)
    .value();
  const { guidCount, stringerCount } = trackingAggr;

  return (
    <React.Fragment>
      <AggregateTd count={guidCount} totalPitches={stringerCount} />
    </React.Fragment>
  );
};

const getLeague = (game) => {
  return game.teams.home.team.league.name;
};

const getLevel = (game) => {
  return game.teams.home.team.sport.abbreviation;
};

const MILBTrackingAccuracyReportTable = ({
  data,
  gameStatuses,
  canCreateIncident,
  groupsToShow,
  dropDownContainer: DropDownContainer,
}) => {
  const incidents = {};
  const headerRow = (
    <TableHeaderRow>
      {infoHeaderCells(canCreateIncident).map((column, idx) => {
        const { label, description, textAlignment, colspan, requiresAdmin } =
          column;

        const hasDescription = !_.isEmpty(description);

        const thContent = !hasDescription ? (
          label
        ) : (
          <Tooltip
            placement="top"
            overlay={<TooltipWrapper>{description}</TooltipWrapper>}
          >
            <PointerText>{label}</PointerText>
          </Tooltip>
        );

        return (
          <Th
            key={idx}
            textAlignment={textAlignment}
            hoverable={hasDescription}
            colSpan={colspan}
          >
            {thContent}
          </Th>
        );
      })}
    </TableHeaderRow>
  );

  const bodyRows =
    data && data.records
      ? data.records.map((record) => {
          const { game, tracking } = record;
          const gamePk = parseInt(game.gamePk, 10);
          const incident =
            _.find(incidents, (incident) => incident.gamePk === gamePk) || {};

          let icon = <Check shape="circle" />;
          if (
            incident &&
            incident.incidentUpdates &&
            incident.incidentUpdates.length > 0
          ) {
            const latestUpdate = incident.incidentUpdates[0];
            if (
              !latestUpdate.status ||
              latestUpdate.status.toUpperCase() === "OPEN"
            ) {
              icon = <Exclamation shape="circle" />;
            } else if (latestUpdate.status.toUpperCase() === "RESOLVED") {
              icon = <CheckWithExclamation shape="circle" />;
            }
          }

          return (
            <Tr key={gamePk}>
              <Td textAlignment="left">
                <div className="flex-between">
                  <MatchupLink game={game} useFullNames={true} />
                  <GameStatusIcon>{icon}</GameStatusIcon>
                </div>
              </Td>
              {canCreateIncident ? (
                <Td noPadding bgcolor={BG_COLOR}>
                  <DropDownContainer game={game} />
                </Td>
              ) : null}
              <Td textAlignment="right">{gamePk}</Td>
              <Td textAlignment="right">{getLeague(game)}</Td>
              <Td textAlignment="right">{getLevel(game)}</Td>
              <Td textAlignment="right">
                {moment(game.gameDate).local().format("LT")}
              </Td>
              {getDataColumns(game, tracking)}
            </Tr>
          );
        })
      : [];

  const AggregateHealthRow = () => {
    const trackingPerGame = _.map(data.records, (x) => x["tracking"]);

    return (
      <TableAggregateHealthRow>
        <Td textAlignment="center" colSpan={canCreateIncident ? 6 : 5}>
          Overall Health
        </Td>
        {getAggregateDataColumns(trackingPerGame)}
      </TableAggregateHealthRow>
    );
  };

  const footerRows = (
    <React.Fragment>
      <Tr>
        <td>&#8203;</td>
      </Tr>
      <Tr>
        <Td noborder />
        <Td noborder />
        <Td noborder />
        <Td noborder />
        <Td noborder />
        <Td bgcolor={WARN_COLOR}>99% to 99.5%</Td>
        <Td noborder />
      </Tr>
      <Tr>
        <Td noborder />
        <Td noborder />
        <Td noborder />
        <Td noborder />
        <Td noborder />
        <Td bgcolor={CRITICAL_COLOR}>{"< 99%"}</Td>
        <Td noborder />
      </Tr>
    </React.Fragment>
  );

  return (
    <Table width="100%">
      <colgroup>
        <col width="26%" />
        <col width="6%" />
        <col width="6%" />
        <col width="15%" />
        <col width="7%" />
        <col width="10%" />
        {canCreateIncident ? <col width="24%" /> : <col width="30%" />}
        {canCreateIncident ? <col width="6%" /> : null}
      </colgroup>
      <thead>{headerRow}</thead>
      <tbody>
        {bodyRows}
        <AggregateHealthRow />
      </tbody>
      <tfoot>{footerRows}</tfoot>
    </Table>
  );
};

export default MILBTrackingAccuracyReportTable;
