import React from "react";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import Tooltip from "rc-tooltip";
import {
  Table,
  TableHeaderRow,
  Tr,
  Th,
  Td,
  TableAggregateHealthRow
} from "../../SetiTable.styled";
import MatchupLink from "../../elements/MatchupLink";
import moment from "moment";
import Check from "../../icons/Check";

import {
  PointerText,
  TooltipWrapper,
  RangeTd,
  WARN_COLOR,
  CRITICAL_COLOR,
  PPD_COLOR,
  GameStatusIcon,
  AggregateTd
} from "../ReportElements";
import AtlanticLeagueTrackingModal from "./AtlanticLeagueTrackingModal";
import styled from "styled-components";
import GameActionsDropDown from "../../GameActionsDropDown";

const ClickableTd = styled(Td)`
  &:hover {
    cursor: pointer;
  }
`;

const stops = [[0, CRITICAL_COLOR], [0.99, WARN_COLOR], [0.995]];

const headerColumns = [
  {
    label: "Game",
    textAlignment: "left"
  },
  {
    label: "GamePK",
    textAlignment: "right"
  },
  {
    label: "Game Time",
    textAlignment: "right"
  },
  { label: "Actions" },
  {
    label: "ABS Tracked",
    description: "Pitches with ABS tracking data",
    textAlignment: "right"
  },
  {
    label: "ABS Compliance",
    description: "Pitches where the ABS Call and Umpire Call Match",
    textAlignment: "right"
  },
  {
    label: "ABS Discrepancies",
    description: "Pitches where ABS and the Umpire disagreed",
    textAlignment: "right"
  },
  {
    label: "ABS Technical",
    description: "Pitches where ABS had technical Issues",
    textAlignment: "right"
  },
  {
    label: "Stats Tracked",
    description: "Pitches with StatsApi tracking",
    textAlignment: "right"
  },
  {
    label: "Pitches w/ Spin Rate",
    textAlignment: "right"
  },
  {
    label: "BIP Tracked",
    textAlignment: "right"
  }
];

const getDataColumns = (game, tracking, store) => {
  const { status = {} } = game;
  if (status.codedGameState === "D") {
    return (
      // PPD the size of the data columns (except for totalPlays and totalHits)
      <Td bgcolor={PPD_COLOR} colSpan={_.keys(tracking).length - 4}>
        PPD
      </Td>
    );
  }

  return (
    <React.Fragment>
      {getAbsPitchesTracked(tracking)}
      {getAbs(tracking)}
      {getAbsDiscrepancies(tracking, store)}
      {getAbsTechnical(tracking, store)}
      {getStatsPitchesTracked(tracking)}
      {getPitchesWithSpinRate(tracking)}
      {getBallsInPlay(tracking)}
    </React.Fragment>
  );
};

const getStatsPitchesTracked = tracking => {
  const { statsTracked, playIds } = tracking;

  return (
    <RangeTd count={statsTracked} totalPitches={playIds.length} stops={stops} />
  );
};

const getAbsPitchesTracked = tracking => {
  const { absTracked, absTotalPitches } = tracking;

  return (
    <RangeTd count={absTracked} totalPitches={absTotalPitches} stops={stops} />
  );
};

const getAbsTechnical = (tracking, store) => {
  const { absTechnicalIssue } = tracking;
  const Elem = absTechnicalIssue.length ? ClickableTd : Td;
  return (
    <Elem onClick={() => store.setSelectedPitches(absTechnicalIssue)}>
      {absTechnicalIssue.length}
    </Elem>
  );
};

const getAbsDiscrepancies = (tracking, store) => {
  const { absDiscrepancies } = tracking;
  const Elem = absDiscrepancies.length ? ClickableTd : Td;
  return (
    <Elem onClick={() => store.setSelectedPitches(absDiscrepancies)}>
      {absDiscrepancies.length}
    </Elem>
  );
};

const getAbs = tracking => {
  const { absMatch, absTotalPitches } = tracking;

  return (
    <RangeTd count={absMatch} totalPitches={absTotalPitches} stops={stops} />
  );
};

const getPitchesWithSpinRate = tracking => {
  const { pitchesWithSpinRate, playIds } = tracking;

  return (
    <RangeTd
      count={pitchesWithSpinRate}
      totalPitches={playIds.length}
      stops={stops}
    />
  );
};

const getBallsInPlay = tracking => {
  const { ballsInPlay, ballsInPlayTracked } = tracking;

  return (
    <RangeTd
      count={ballsInPlayTracked}
      totalPitches={ballsInPlay}
      stops={stops}
    />
  );
};

const getAggregateDataColumns = trackingPerGame => {
  let trackingAggr = _.transform(
    trackingPerGame[0],
    (result, value, key) => (result[key] = 0),
    {}
  );
  _.reduce(
    trackingPerGame,
    (trackingAggr, x) => {
      return _.forEach(
        trackingAggr,
        (value, key) => (trackingAggr[key] += x[key])
      );
    },
    trackingAggr
  );

  const { guids, playIds } = trackingAggr;

  return (
    <React.Fragment>
      <AggregateTd count={guids} totalPitches={playIds} />
    </React.Fragment>
  );
};

const AtlanticTrackingAccuracyReportTable = ({
  data = [],
  transitionToCreateIncident,
  canCreateIncident,
  rootStore: { atlanticTrackingAccuracyReportSource }
}) => {
  const gameActionOptions = [
    { label: "Create Incident", value: "create_incident" }
  ];

  const headerRow = (
    <TableHeaderRow>
      {headerColumns
        .filter(header => canCreateIncident || header.label !== "Actions")
        .map((column, idx) => {
          const { label, description, textAlignment, colspan } = column;
          const hasDescription = !_.isEmpty(description);

          const thContent = !hasDescription ? (
            label
          ) : (
            <Tooltip
              placement="top"
              overlay={<TooltipWrapper>{description}</TooltipWrapper>}
            >
              <PointerText>{label}</PointerText>
            </Tooltip>
          );

          return (
            <Th
              key={idx}
              textAlignment={textAlignment}
              hoverable={hasDescription}
              colSpan={colspan}
            >
              {thContent}
            </Th>
          );
        })}
    </TableHeaderRow>
  );

  const bodyRows =
    data && data.records
      ? data.records.map(record => {
          const { game } = record;

          return (
            <Tr key={game.gamePk}>
              <Td textAlignment="left">
                <div className="flex-between">
                  <MatchupLink game={game} useFullNames={true} />
                  <GameStatusIcon>
                    <Check shape="circle" />
                  </GameStatusIcon>
                </div>
              </Td>
              <Td textAlignment="right">{game.gamePk}</Td>
              <Td textAlignment="right">
                {moment(game.gameDate)
                  .local()
                  .format("LT")}
              </Td>
              {canCreateIncident ? (
                <Td noPadding>
                  <GameActionsDropDown
                    game={game}
                    gameActionOptions={gameActionOptions}
                    transitionToCreateIncident={transitionToCreateIncident}
                  />
                </Td>
              ) : null}
              {getDataColumns(
                game,
                record,
                atlanticTrackingAccuracyReportSource
              )}
            </Tr>
          );
        })
      : [];

  const AggregateHealthRow = () => {
    const trackingPerGame = _.map(data.records, x => x["tracking"]);

    return (
      <TableAggregateHealthRow>
        <Td textAlignment="center" colSpan="4">
          Overall Health
        </Td>
        {getAggregateDataColumns(trackingPerGame)}
      </TableAggregateHealthRow>
    );
  };

  const footerRows = (
    <React.Fragment>
      <Tr>
        <td>&#8203;</td>
      </Tr>
      <Tr>
        <Td noborder />
        <Td noborder />
        <Td noborder />
        {canCreateIncident && <Td noborder />}
        <Td colSpan={2} bgcolor={WARN_COLOR}>
          99% to 99.5%
        </Td>
        <Td noborder />
        <Td noborder />
        <Td colSpan={3} bgcolor={WARN_COLOR}>
          99% to 99.5%
        </Td>
      </Tr>
      <Tr>
        <Td noborder />
        <Td noborder />
        <Td noborder />
        {canCreateIncident && <Td noborder />}
        <Td colSpan={2} bgcolor={CRITICAL_COLOR}>
          {"< 99%"}
        </Td>
        <Td noborder />
        <Td noborder />
        <Td colSpan={3} bgcolor={CRITICAL_COLOR}>
          {"< 99%"}
        </Td>
      </Tr>
    </React.Fragment>
  );

  return (
    <div>
      <AtlanticLeagueTrackingModal />
      <Table width="100%">
        <colgroup>
          <col width="29%" />
          <col width="6%" />
          <col width="6%" />
          {canCreateIncident && <col width="11%" />}
          <col width="7%" />
          <col width="7%" />
          <col width="7%" />
          <col width="7%" />
          <col width="7%" />
          <col width="7%" />
          <col width="7%" />
          <col width="10%" />
        </colgroup>
        <thead>{headerRow}</thead>
        <tbody>
          {bodyRows}
          {AggregateHealthRow}
        </tbody>
        <tfoot>{footerRows}</tfoot>
      </Table>
    </div>
  );
};

export default inject("rootStore")(
  observer(AtlanticTrackingAccuracyReportTable)
);
