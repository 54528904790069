import _ from "lodash";
import { action, computed, extendObservable, reaction } from "mobx";
import Groups from "../constants/Groups";
import { RouteConstants } from "../constants/RouteConstants";
import MLBTrackingAccuracyReportContainer from "../containers/MLBTrackingAccuracyReportContainer";
import MILBTrackingAccuracyReportContainer from "../containers/MILBTrackingAccuracyReportContainer";
import AtlanticTrackingAccuracyReportContainer from "../containers/AtlanticTrackingAccuracyReportContainer";
import AFLTrackingAccuracyReportContainer from "../containers/AFLTrackingAccuracyReportContainer";
import NonMLBTrackingAccuracyReportContainer from "../containers/NonMLBTrackingAccuracyReportContainer";
import FSLTrackingAccuracyReportContainer from "../containers/FSLTrackingAccuracyReportContainer";
import LatestIncidentsContainer from "../containers/LatestIncidentsContainer";
import AAATrackingAccuracyReportContainer from "../containers/AAATrackingAccuracyReportContainer";
import AATrackingAccuracyReportContainer from "../containers/AATrackingAccuracyReportContainer";
import HighATrackingAccuracyReportContainer from "../containers/HighATrackingAccuracyReportContainer";
import ATrackingAccuracyReportContainer from "../containers/ATrackingAccuracyReportContainer";
import RookieTrackingAccuracyReportContainer from "../containers/RookieTrackingAccuracyReportContainer";
import AmateurTrackingAccuracyReportContainer from "../containers/AmateurTrackingAccuracyReportContainer";
import WinterTrackingAccuracyReportContainer from "../containers/WinterTrackingAccuracyReportContainer";
import InternationalIndependentTrackingAccuracyReportContainer from "../containers/InternationalIndependentTrackingAccuracyReportContainer";
import moment from "moment-timezone";

class AuthStore {
  constructor(routerStore, auth) {
    this.routerStore = routerStore;
    this.auth = auth;

    const dateParam = this.routerStore.location.search
      ? new URLSearchParams(this.routerStore.location.search).get("date")
      : false;

    const now = moment().tz("America/New_York");
    this.defaults = {
      date: dateParam
        ? dateParam
        : (now.hour() < 10 ? now.subtract(1, "day") : now).format("YYYY-MM-DD"),
      selectedPitches: [],
    };

    this.routerStore.setDate(
      this.routerStore.date ? this.routerStore.date : this.defaults["date"],
    );

    extendObservable(this, {
      isAuthenticated: false,
      isGettingUser: false,
      error: {
        isVisible: false,
        message: "",
      },
      user: null,
      username: computed(() => (this.user ? this.user.email : null)),
      groups: computed(() => (this.user ? this.user.groups : null)),
      hasIncidentEdit: computed(
        () => this.user && _.includes(this.user.groups, Groups.ADMIN),
      ),
      isAdmin: computed(
        () => this.user && _.includes(this.user.groups, Groups.ADMIN),
      ),
      isAdminOrSuperUser: computed(
        () =>
          this.user &&
          (_.includes(this.user.groups, Groups.ADMIN) ||
            _.includes(this.user.groups, Groups.SUPER_USER)),
      ),
      logout: action(async () => {
        this.isAuthenticated = false;
        this.auth.logout(RouteConstants.BASE);
      }),
      pages: computed(() => [
        {
          path: RouteConstants.BASE,
          label: "MLB",
          isProtected: true,
          isActive: this.routerStore.isMLBTrackingAccuracyReportTab,
          component: MLBTrackingAccuracyReportContainer,
          isExact: true,
          isHiddenOnNav: false,
        },
        {
          path: RouteConstants.NON_MLB_STATCAST,
          label: "Non-MLB Statcast",
          isProtected: true,
          isActive: this.routerStore.isNonMLBTrackingAccuracyReportTab,
          component: NonMLBTrackingAccuracyReportContainer,
          isExact: true,
          isHiddenOnNav: false,
        },

        {
          path: RouteConstants.AAA_TRACKING,
          label: "AAA",
          isProtected: true,
          isActive: this.routerStore.isAAATrackingAccuracyReportTab,
          component: AAATrackingAccuracyReportContainer,
          isExact: true,
          isHiddenOnNav: false,
        },
        {
          path: RouteConstants.AA_TRACKING,
          label: "AA",
          isProtected: true,
          isActive: this.routerStore.isAATrackingAccuracyReportTab,
          component: AATrackingAccuracyReportContainer,
          isExact: true,
          isHiddenOnNav: false,
        },
        {
          path: RouteConstants.HighA_TRACKING,
          label: "High-A",
          isProtected: true,
          isActive: this.routerStore.isHighATrackingAccuracyReportTab,
          component: HighATrackingAccuracyReportContainer,
          isExact: true,
          isHiddenOnNav: false,
        },
        {
          path: RouteConstants.A_TRACKING,
          label: "A",
          isProtected: true,
          isActive: this.routerStore.isATrackingAccuracyReportTab,
          component: ATrackingAccuracyReportContainer,
          isExact: true,
          isHiddenOnNav: false,
        },
        {
          path: RouteConstants.ROOKIE_TRACKING,
          label: "Rookie",
          isProtected: true,
          isActive: this.routerStore.isRookieTrackingAccuracyReportTab,
          component: RookieTrackingAccuracyReportContainer,
          isExact: true,
          isHiddenOnNav: false,
        },
        {
          path: RouteConstants.AMATEUR_TRACKING,
          label: "Amateur",
          isProtected: true,
          isActive: this.routerStore.isAmateurTrackingAccuracyReportTab,
          component: AmateurTrackingAccuracyReportContainer,
          isExact: true,
          isHiddenOnNav: false,
        },
        {
          path: RouteConstants.WINTER_TRACKING,
          label: "Winter",
          isProtected: true,
          isActive: this.routerStore.isWinterTrackingAccuracyReportTab,
          component: WinterTrackingAccuracyReportContainer,
          isExact: true,
          isHiddenOnNav: false,
        },
        {
          path: RouteConstants.INDEPENDENT_INTERNATIONAL_TRACKING,
          label: "Int & Indy",
          isProtected: true,
          isActive:
            this.routerStore
              .isInternationalIndependentTrackingAccuracyReportTab,
          component: InternationalIndependentTrackingAccuracyReportContainer,
          isExact: true,
          isHiddenOnNav: false,
        },
        {
          path: RouteConstants.MILB_TRACKING,
          label: "MiLB (Video Only)",
          isProtected: true,
          isActive: this.routerStore.isMILBTrackingAccuracyReportTab,
          component: MILBTrackingAccuracyReportContainer,
          isExact: true,
          isHiddenOnNav: false,
        },
        {
          path: RouteConstants.LATEST_INCIDENTS,
          label: "Latest Incidents",
          isProtected: true,
          isActive: this.routerStore.isLatestIncidentsTab,
          component: LatestIncidentsContainer,
          isExact: true,
          isHiddenOnNav: false,
        },
      ]),
      checkAuthentication: action(async () => {
        //okta's isAuthenticated only requires an accessToken, we need the idToken for getting the user's information
        const idToken = await this.auth._oktaAuth.tokenManager.get("idToken");
        const isAuthenticated =
          (await this.auth.isAuthenticated()) && idToken !== undefined;
        if (isAuthenticated !== this.isAuthenticated) {
          this.isAuthenticated = isAuthenticated;
        }
      }),
      onSuccess: action(async (res) => {
        if (res.status === "SUCCESS") {
          /**
           * this.auth.redirect sets the tokens in localStorage
           * However the timing is unpredictable and can be too slow for initial api requests
           * getWithoutPrompt allows us to guarantee that the accessToken is available on initial login.
           */
          const tokens = await this.auth._oktaAuth.token.getWithoutPrompt({
            sessionToken: res.session.token,
            responseType: ["token", "id_token"],
          });
          const [accessToken, idToken] = tokens;
          const accessTokenString = JSON.stringify({ accessToken, idToken });
          window.localStorage.setItem("okta-token-storage", accessTokenString);
          return this.auth.redirect({
            sessionToken: res.session.token,
          });
        }
      }),
      onError: action((err) => {
        console.log("error logging in", err);
      }),
      getUser: action(async () => {
        this.isGettingUser = true;
        try {
          await this.checkAuthentication();
          this.user = await this.auth.getUser();
          if (this.user && this.isAuthenticated) {
            this.isGettingUser = false;
            return this.user;
          } else if (this.user && !this.isAuthenticated) {
            // access token is not valid, logout and try again
            this.logout();
          }
        } catch (e) {
          //Something went wrong, clear out localStorage to make the user login again
          window.localStorage.clear();
        }
      }),
    });
  }
}

export default AuthStore;
