import { action, extendObservable } from "mobx";

class TableControlsStore {
  constructor() {
    const initialState = this.getInitialState();

    extendObservable(this, {
      state: initialState,
      updateState: action(state => {
        this.state = state;
        localStorage.setItem("tableControls", JSON.stringify(state));
      })
    });
  }

  getInitialState() {
    const defaultState = {
      showPitches: true,
      showVideo: true,
      showHitting: true,
      showPlayers: true
    };
    return JSON.parse(localStorage.getItem("tableControls")) || defaultState;
  }
}

export default TableControlsStore;
