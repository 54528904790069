import React from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import rem from "../utils/rem";
import Loading from "../components/Loading";
import AdminOrSuperProtected from "../components/protected/AdminOrSuperProtected";
import AFLTrackingAccuracyReportTable from "../components/accuracyReports/AFLTrackingAccuracyReportTable";
import IncidentOverviewTable from "../components/IncidentOverviewTable";
import IncidentTypes from "../constants/IncidentTypes";

const Wrapper = styled.div`
  width: 1330px;
  margin: ${rem(30)};
`;

const ContentWrapper = styled.div`
  margin: ${rem(30)} 0;
`;

const DateInput = styled.input`
  text-align: center;
  line-height: ${rem(16)};
  border-radius: ${rem(4)};
  border: 1px solid #aeaeae;
  font-size: ${rem(13)};
  padding: ${rem(6)} ${rem(10)} ${rem(6)};
  cursor: pointer;
`;

const Description = styled.div`
  padding-bottom: ${rem(20)};
`;

const Footer = styled.div`
  font-size: ${rem(10)};
  padding: ${rem(10)} 0;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexItem = styled.div`
  align-self: flex-end;
`;

class AFLTrackingAccuracyReportContainer extends React.Component {
  constructor(props) {
    super(props);
    this._handleDateChange = this._handleDateChange.bind(this);
  }

  _handleDateChange(date) {
    const {
      aflTrackingAccuracyReportSource: store,
      veracityStore
    } = this.props.rootStore;
    const formattedDate = date ? date.format("YYYY-MM-DD") : "";
    store.updateDate(formattedDate);
    veracityStore.fetchIncidents([IncidentTypes.AFL], formattedDate);
  }

  componentDidMount() {
    const {
      aflTrackingAccuracyReportSource: store,
      veracityStore
    } = this.props.rootStore;
    const date = store.get("date");
    store.startPolling();
    veracityStore.fetchIncidents([IncidentTypes.AFL], date);
  }

  componentWillUnmount() {
    const { aflTrackingAccuracyReportSource: store } = this.props.rootStore;
    store.stopPolling();
  }

  render() {
    const {
      aflTrackingAccuracyReportSource: store,
      authStore,
      veracityStore
    } = this.props.rootStore;
    const selectedDate = new moment(store.get("date"));

    return (
      <Wrapper>
        <FlexContainer>
          <FlexItem>
            <h1>AFL Data Quality Report</h1>
          </FlexItem>
        </FlexContainer>
        <Description>
          A real-time pitch-by-pitch data report that monitors the overall
          quality of the Arizona Fall League Baseball's data and video systems.
        </Description>
        <DatePicker
          selected={selectedDate}
          onChange={date => {
            this._handleDateChange(date);
            store.pushUrlParams();
          }}
          dateFormat="LL"
          customInput={<DateInput />}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
        <div>
          {store.isLoading ? (
            <Loading />
          ) : (
            <ContentWrapper>
              <AFLTrackingAccuracyReportTable
                data={store.data}
                transitionToCreateIncident={store.transitionToCreateIncident}
                canCreateIncident={authStore.hasIncidentEdit}
              />
              <Footer>Data retrieved at {store.updatedAt}</Footer>
            </ContentWrapper>
          )}
        </div>
        <IncidentOverviewTable data={veracityStore.incidentsOverview} />
      </Wrapper>
    );
  }
}

export default inject("rootStore")(
  AdminOrSuperProtected(observer(AFLTrackingAccuracyReportContainer))
);
