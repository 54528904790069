import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import moment from "moment";
import Modal from "react-modal";
import { Table, TableHeaderRow, Td, Th, Tr } from "../../SetiTable.styled";

const ExternalLink = styled.i.attrs({
  className: "fa fa-external-link"
})``;

const AtlanticLeagueTrackingModal = ({
  rootStore: { atlanticTrackingAccuracyReportSource }
}) => {
  const getGamedayUrl = pitch => {
    let url = "";
    if (pitch.pitchLocation) {
      const gamePk = pitch.pitchLocation.gamePk;
      const timestamp = moment(pitch.endTime)
        .utc()
        .format("YYYYMMDD_HHmmss");
      url = `http://prod-gameday.mlbstatic.com/app-atlantic/4.1.8-atlantic.0/bundle/index.html#game=${gamePk},game_state=live,game_tab=play-by-play,tfs=${timestamp},tfsHold=true`;
    }
    return url;
  };

  return (
    <Modal
      isOpen={atlanticTrackingAccuracyReportSource.showModal}
      contentLabel="Additional Pitch Info"
      onRequestClose={() =>
        atlanticTrackingAccuracyReportSource.setSelectedPitches([])
      }
      ariaHideApp={false}
      className="deleteModal"
    >
      <Table>
        <thead>
          <TableHeaderRow>
            <Th>ABS Decision</Th>
            <Th>Umpire Decision</Th>
            <Th>Inning</Th>
            <Th>At Bat #</Th>
            <Th>Pitch Of At Bat</Th>
            <Th>Strike</Th>
            <Th>Ball</Th>
            <Th>Gameday</Th>
          </TableHeaderRow>
        </thead>
        <tbody>
          {atlanticTrackingAccuracyReportSource.selectedPitches.map(pitch => {
            let url = getGamedayUrl(pitch);
            return (
              <Tr>
                <Td>{pitch.absDecision ? pitch.absDecision : "N/A"}</Td>
                <Td>{pitch.umpDecision ? pitch.umpDecision : "N/A"}</Td>
                <Td>
                  {pitch.topInning ? "T" : "B"}
                  {pitch.inning}
                </Td>
                <Td>{pitch.atBatNumber}</Td>
                <Td>{pitch.pitchOfAtBat}</Td>
                <Td>{pitch.count ? pitch.count.strikes : "N/A"}</Td>
                <Td>{pitch.count ? pitch.count.balls : "N/A"}</Td>
                <Td>
                  {url.length ? (
                    <a href={url} target={"_blank"}>
                      <ExternalLink />
                    </a>
                  ) : null}
                </Td>
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </Modal>
  );
};

export default inject("rootStore")(observer(AtlanticLeagueTrackingModal));
