import IncidentTypes from "../constants/IncidentTypes";
import { RouteConstants } from "../constants/RouteConstants";
import TrackingAccuracyReportStore from "./trackingAccuracyReportStore";

class WinterTrackingAccuracyReportStore extends TrackingAccuracyReportStore {
  fetchTrackingAccuracyReport(date) {
    return this.veracityApi.fetchWinterTrackingAccuracyReport(date);
  }

  cancelTrackingAccuracyReport() {
    return this.veracityApi.cancelWinterTrackingAccuracyReport();
  }

  isTrackingTabActive() {
    return this.routerStore.isWinterTrackingAccuracyReportTab;
  }

  updateNewIncidentMetadata(game) {
    return this.veracityStore.updateNewIncidentMetadata(
      IncidentTypes.WIN,
      game,
      this.get("date"),
      RouteConstants.WINTER_TRACKING,
    );
  }
}

export default WinterTrackingAccuracyReportStore;
