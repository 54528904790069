import { Provider } from "mobx-react";
import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Navbar from "./containers/Navbar";
import CreateNewIncident from "./containers/CreateNewIncident";
import OktaLogin from "./containers/OktaLogin";
import ViewIncident from "./containers/ViewIncident";
import RootStore from "./stores/rootStore";
import { RouteConstants } from "./constants/RouteConstants";
import { Security, ImplicitCallback, SecureRoute } from "@okta/okta-react";
import { ToastContainer } from "react-toastify";

const rootStore = new RootStore();
const theme = {
  lightGray: "rgb(240, 240, 240)",
  darkRed: "rgb(132, 0, 7)",
  brown: "rgb(130, 85, 8)",
  green: "rgb(17, 132, 44)",
  primary: "palevioletred"
};

class App extends React.Component {
  render() {
    return (
      <Provider rootStore={rootStore}>
        <Router history={rootStore.history}>
          <Security auth={rootStore.authStore.auth}>
            <ThemeProvider theme={theme}>
              <div>
                <Navbar />
                <ToastContainer />
                {rootStore.authStore.pages.map(page => {
                  if (page.subRoutes) {
                    return page.subRoutes.map(subPage => {
                      return (
                        <SecureRoute
                          key={subPage.path}
                          exact={subPage.isExact}
                          path={subPage.path}
                          component={subPage.component}
                        />
                      );
                    });
                  } else {
                    return (
                      <SecureRoute
                        key={page.path}
                        exact={page.isExact}
                        path={page.path}
                        component={page.component}
                      />
                    );
                  }
                })}
                <Route
                  path={RouteConstants.LOGIN}
                  render={() => (
                    <OktaLogin baseUrl={process.env.REACT_APP_OKTA_LOGIN_URL} />
                  )}
                />
                <Route path="/implicit/callback" component={ImplicitCallback} />
                <Switch>
                  <SecureRoute
                    exact
                    path={RouteConstants.INCIDENTS_NEW}
                    component={CreateNewIncident}
                  />
                  <SecureRoute
                    path={RouteConstants.INCIDENTS}
                    component={ViewIncident}
                  />
                </Switch>
              </div>
            </ThemeProvider>
          </Security>
        </Router>
      </Provider>
    );
  }
}

export default App;
