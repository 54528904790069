import React from "react";
import { getMatchupSlug } from "../../utils/game";

const MatchUpLink = ({ game, useFullNames = false }) => {
  const { gamePk, teams } = game;
  const matchupSlug = getMatchupSlug(game, useFullNames);

  return (
    <>
      <a
        href={`https://research.mlb.com/games/${gamePk}/quality`}
        target="_blank"
      >
        {matchupSlug} {"[" + teams?.home?.team?.sport?.abbreviation + "]"}
      </a>
    </>
  );
};

export default MatchUpLink;
