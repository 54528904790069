import IncidentTypes from "../constants/IncidentTypes";
import { RouteConstants } from "../constants/RouteConstants";
import TrackingAccuracyReportStore from "./trackingAccuracyReportStore";

class AATrackingAccuracyReportStore extends TrackingAccuracyReportStore {
  fetchTrackingAccuracyReport(date) {
    return this.veracityApi.fetchAATrackingAccuracyReport(date);
  }

  cancelTrackingAccuracyReport() {
    return this.veracityApi.cancelAATrackingAccuracyReport();
  }

  isTrackingTabActive() {
    return this.routerStore.isAATrackingAccuracyReportTab;
  }

  updateNewIncidentMetadata(game) {
    return this.veracityStore.updateNewIncidentMetadata(
      IncidentTypes.AA,
      game,
      this.get("date"),
      RouteConstants.AA_TRACKING,
    );
  }
}

export default AATrackingAccuracyReportStore;
