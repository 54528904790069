import React from "react";
import _ from "lodash";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Modal from "react-modal";
import rem from "../utils/rem";
import { getMatchupSlug } from "../utils/game";
import IncidentForm from "../components/IncidentForm";
import GameIncidentUpdatesTable from "../components/GameIncidentUpdatesTable";
import IncidentMetadata from "../components/IncidentMetadata";
import Editable from "../components/Editable";
import VerifyDeleteModal from "../components/VerifyDeleteModal";
import BaseChevron from "../components/icons/Chevron";
import Trash from "../components/icons/Trash";

const Wrapper = styled.div`
  margin: ${rem(30)};
  width: 1330px;
`;

const IncidentNameWrapper = styled.div`
  padding: ${rem(20)} 0;
  font-size: ${rem(20)};
`;

const GameSlug = styled.div`
  font-size: ${rem(24)};
  font-weight: 500;
`;

const Chevron = BaseChevron``;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexItem = styled.div`
  flex: ${props => props.flex};
  align-self: flex-end;
`;

const FormExpander = styled.div`
  cursor: pointer;
  margin: 20px 0;
`;

const SmallText = styled.span`
  font-size: ${rem(13)};
  color: #333;
`;

const DeleteIcon = styled.span`
  float: right;
  color: red;
  cursor: pointer;
`;

class ViewIncident extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showForm: false, modalIsOpen: false };

    this.toggleModal = this.toggleModal.bind(this);
    this.deleteIncident = this.deleteIncident.bind(this);
  }

  componentDidMount() {
    const { veracityStore: store } = this.props.rootStore;
    const { params } = this.props.match;

    store.fetchIncident(params.id);
  }

  deleteIncident() {
    const { params } = this.props.match;
    const { veracityStore: store } = this.props.rootStore;
    store.deleteIncident(params.id);
  }

  toggleModal() {
    const { modalIsOpen } = this.state;
    this.setState({ modalIsOpen: !modalIsOpen });
  }

  render() {
    const { veracityStore: store, authStore } = this.props.rootStore;
    const { showForm, modalIsOpen } = this.state;
    const incident = store.singleIncident;
    const canEdit = authStore.hasIncidentEdit;
    // TODO: Proper loading
    if (_.isEmpty(incident)) {
      return null;
    }
    return (
      <Wrapper>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.toggleModal}
          ariaHideApp={false}
          className="deleteModal"
        >
          <VerifyDeleteModal
            message="Are you sure you want to remove this incident?"
            handleDelete={this.deleteIncident}
            toggleModal={this.toggleModal}
          />
        </Modal>
        <h1>
          Data Quality Report - Incident - #{incident.id}
          {canEdit && (
            <DeleteIcon onClick={this.toggleModal}>
              <Trash />
            </DeleteIcon>
          )}
        </h1>
        <GameSlug>
          {incident.systemWide
            ? "System Wide Incident"
            : `${getMatchupSlug(incident.game)}`}{" "}
          - {moment(incident.incidentDate).format("LL")}
        </GameSlug>
        <IncidentNameWrapper>
          <Editable
            disabled={!canEdit}
            handleSave={value => store.updateSingleIncidentName(value)}
          >
            {incident.name}
          </Editable>
        </IncidentNameWrapper>
        <IncidentMetadata incident={incident} />
        <hr />
        <FlexContainer>
          <FlexItem>
            <h2>
              Incident History <SmallText>(all times Eastern)</SmallText>
            </h2>
          </FlexItem>
          {canEdit ? (
            <FlexItem>
              <FormExpander
                onClick={() => {
                  this.setState(prevState => {
                    const { showForm } = prevState;
                    return { showForm: !showForm };
                  });
                }}
              >
                <Chevron direction={showForm ? "up" : "down"} /> Update Incident
              </FormExpander>
            </FlexItem>
          ) : null}
        </FlexContainer>
        {showForm ? (
          <IncidentForm
            onSubmit={store.createIncidentUpdate}
            afterSubmit={() =>
              store.fetchIncident(incident.id).then(() => {
                this.setState({ showForm: false });
              })
            }
            isEditing
            previousIncident={incident}
            incidentType={incident.incidentType}
          />
        ) : null}
        <GameIncidentUpdatesTable />
      </Wrapper>
    );
  }
}

export default withRouter(inject("rootStore")(observer(ViewIncident)));
