import React from "react";
import { inject, observer } from "mobx-react";
import nonTrackingGameActionOptions from "../constants/nonTrackingGameActionOptions";
import GameActionsDropDown from "../components/GameActionsDropDown";

const MiLBGameActionDropDownContainer = ({ rootStore, game }) => {
  const { milbGameActionStore } = rootStore;

  return (
    <GameActionsDropDown
      game={game}
      gameActionOptions={nonTrackingGameActionOptions}
      transitionToCreateIncident={
        milbGameActionStore.milbTrackingAccuracyReportSource
          .transitionToCreateIncident
      }
      resendGame={milbGameActionStore.resendGame}
    />
  );
};

export default inject("rootStore")(observer(MiLBGameActionDropDownContainer));
