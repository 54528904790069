import IncidentTypes from "../constants/IncidentTypes";
import { RouteConstants } from "../constants/RouteConstants";
import TrackingAccuracyReportStore from "./trackingAccuracyReportStore";

class AAATrackingAccuracyReportStore extends TrackingAccuracyReportStore {
  fetchTrackingAccuracyReport(date) {
    return this.veracityApi.fetchAAATrackingAccuracyReport(date);
  }

  cancelTrackingAccuracyReport() {
    return this.veracityApi.cancelAAATrackingAccuracyReport();
  }

  isTrackingTabActive() {
    return this.routerStore.isAAATrackingAccuracyReportTab;
  }

  updateNewIncidentMetadata(game) {
    return this.veracityStore.updateNewIncidentMetadata(
      IncidentTypes.AAA,
      game,
      this.get("date"),
      RouteConstants.AAA_TRACKING,
    );
  }
}

export default AAATrackingAccuracyReportStore;
