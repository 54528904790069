import React from "react";
import { inject, observer } from "mobx-react";
import BaseMLBTrackingAccuracyReportContainer from "./BaseMLBTrackingAccuracyReportContainer";
import NonMLBTrackingAccuracyReportTable from "../components/accuracyReports/NonMLBTrackingAccuracyReportTable";
import IncidentTypes from "../constants/IncidentTypes";
import NonMLBGameActionDropDownContainer from "./NonMLBGameActionDropDownContainer";

class NonMLBTrackingAccuracyReportContainer extends React.Component {
  render() {
    const rootStore = this.props.rootStore;
    const description =
      "A real-time pitch-by-pitch data report that monitors the overall quality of data and video systems used for non-MLB events, such as High School, Collegiate, and Minor League games.";

    const reportTable = (
      <NonMLBTrackingAccuracyReportTable
        data={rootStore.nonMLBGameActionStore.trackingAccuracyReportSource.data}
        gameStatuses={rootStore.veracityStore.gameStatuses}
        canCreateIncident={rootStore.authStore.hasIncidentEdit}
        groupsToShow={rootStore.tableControlsStore.state}
        dropDownContainer={NonMLBGameActionDropDownContainer}
      />
    );
    return (
      <BaseMLBTrackingAccuracyReportContainer
        header="Non-MLB Statcast Data & Coaching Video Report"
        description={description}
        reportSource={
          rootStore.nonMLBGameActionStore.trackingAccuracyReportSource
        }
        reportTable={reportTable}
        incidentTypesToFetch={[
          IncidentTypes.NON_MLB,
          IncidentTypes.SYSTEM,
          IncidentTypes.AAA,
          IncidentTypes.AA,
          IncidentTypes.HighA,
          IncidentTypes.A,
          IncidentTypes.ROK,
          IncidentTypes.WIN,
          IncidentTypes.Amateur,
        ]}
        showSystemIncidentButton={rootStore.authStore.hasIncidentEdit}
      />
    );
  }
}

export default inject("rootStore")(
  observer(NonMLBTrackingAccuracyReportContainer),
);
