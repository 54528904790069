import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import BaseChevron from "../components/icons/Chevron";
import rem from "../utils/rem";
import logo from "../assets/logo-dark.png";
import { RouteConstants } from "../constants/RouteConstants";

const Chevron = BaseChevron`
  font-size: 60%;
  position: relative;
  top: ${rem(-2)};
`;

const NavWrapper = styled.nav`
  height: ${rem(60)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  padding: 0 ${rem(25)};
`;

const navItem = css`
  display: inline-block;
  vertical-align: middle;
  color: ${(props) => (props.isActive ? "#111" : "#9e9e9e")};
  text-decoration: none;
  margin: 0 ${rem(5)};
  padding: 0 ${rem(5)};
  cursor: pointer;
`;

const NavLink = styled(Link).attrs({
  className: "seti-nav-link",
})`
  ${navItem} &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const LogoutLink = styled.div`
  ${navItem};
`;

const Logo = styled.img`
  max-width: 42px;
  width: 100%;
`;

const Dropdown = styled.div.attrs({
  className: "seti-dropdown",
})`
  ${navItem} position: relative;
  z-index: 10;
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #fff;
  width: ${rem(180)};
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);

  ${Dropdown}:hover & {
    display: block;
  }
`;

const DropdownItem = styled.div`
  &&& {
    display: block;
    padding: ${rem(10)};
    color: inherit;

    &:hover {
      background-color: #eee;
    }

    .seti-nav-link {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

const ResourceLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

class Navbar extends Component {
  render() {
    const { authStore } = this.props.rootStore;

    return (
      <NavWrapper>
        <div>
          <NavLink to={RouteConstants.BASE}>
            <Logo src={logo} />
          </NavLink>
          {authStore.pages.map((link, idx) => {
            if (
              link.isProtected &&
              !link.isHiddenOnNav &&
              authStore.isAuthenticated
            ) {
              if (link.subRoutes) {
                return (
                  <Dropdown key={idx}>
                    <div>
                      {link.label} <Chevron direction="down" />
                    </div>
                    <DropdownContent>
                      {link.subRoutes.map((subLink, subIdx) => {
                        return (
                          <DropdownItem key={subIdx}>
                            <NavLink
                              to={subLink.path}
                              isActive={subLink.isActive}
                            >
                              {subLink.label}
                            </NavLink>
                          </DropdownItem>
                        );
                      })}
                    </DropdownContent>
                  </Dropdown>
                );
              } else {
                return (
                  <NavLink key={idx} to={link.path} isActive={link.isActive}>
                    {link.label}
                  </NavLink>
                );
              }
            } else {
              return null;
            }
          })}
          <ExternalResources />
        </div>
        <div>
          {authStore.isAuthenticated ? (
            <LogoutLink onClick={authStore.logout}>Logout</LogoutLink>
          ) : (
            <NavLink to={RouteConstants.LOGIN}>Login</NavLink>
          )}
        </div>
      </NavWrapper>
    );
  }
}

const ExternalResources = (props) => {
  return (
    <Dropdown>
      Resources <Chevron direction="down" />
      <DropdownContent>
        <DropdownItem>
          <ResourceLink href="https://research.mlb.com">
            Research Dashboard
          </ResourceLink>
        </DropdownItem>
        <DropdownItem>
          <ResourceLink href="https://inside.mlb.com/group/mlb-baseball-technology-space">
            Inside MLB Technology Blog
          </ResourceLink>
        </DropdownItem>
      </DropdownContent>
    </Dropdown>
  );
};

export default inject("rootStore")(observer(Navbar));
