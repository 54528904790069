import React from "react";
import { inject, observer } from "mobx-react";
import MILBTrackingAccuracyReportTable from "../components/accuracyReports/MILBTrackingAccuracyReportTable";
import IncidentTypes from "../constants/IncidentTypes";
import BaseMiLBTrackingAccuracyReportContainer from "./BaseMiLBTrackingAccuracyReportContainer";
import MILBGameActionDropDownContainer from "./MILBGameActionDropDownContainer";

const MILBTrackingAccuracyReportContainer = ({ rootStore }) => {
  const description =
    "A real-time pitch-by-pitch data report that monitors the overall quality of video systems for non-tracking Minor League Baseball games.";

  const reportTable = (
    <MILBTrackingAccuracyReportTable
      data={rootStore.milbGameActionStore.milbTrackingAccuracyReportSource.data}
      gameStatuses={rootStore.veracityStore.gameStatuses}
      canCreateIncident={rootStore.authStore.hasIncidentEdit}
      groupsToShow={rootStore.tableControlsStore.state}
      dropDownContainer={MILBGameActionDropDownContainer}
    />
  );
  return (
    <BaseMiLBTrackingAccuracyReportContainer
      header="MiLB Non-Tracking Coaching Video Report"
      description={description}
      reportSource={
        rootStore.milbGameActionStore.milbTrackingAccuracyReportSource
      }
      reportTable={reportTable}
      incidentTypesToFetch={[IncidentTypes.MILB, IncidentTypes.SYSTEM]}
      showSystemIncidentButton={rootStore.authStore.hasIncidentEdit}
    />
  );
};

export default inject("rootStore")(
  observer(MILBTrackingAccuracyReportContainer),
);
