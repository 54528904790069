import IncidentTypes from "../constants/IncidentTypes";
import { RouteConstants } from "../constants/RouteConstants";
import TrackingAccuracyReportStore from "./trackingAccuracyReportStore";

class MiLBTrackingAccuracyReportStore extends TrackingAccuracyReportStore {
  fetchTrackingAccuracyReport(date) {
    return this.veracityApi.fetchTrackingMILBAccuracyReport(date);
  }

  cancelTrackingAccuracyReport() {
    return this.veracityApi.cancelTrackingMILBAccuracyReport();
  }

  isTrackingTabActive() {
    return this.routerStore.isMILBTrackingAccuracyReportTab;
  }

  updateNewIncidentMetadata(game) {
    return this.veracityStore.updateNewIncidentMetadata(
      IncidentTypes.MILB,
      game,
      this.get("date"),
      RouteConstants.MILB_TRACKING,
    );
  }
}

export default MiLBTrackingAccuracyReportStore;
